<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
    />
    <b-modal
      v-model="modalIsOpen"
      title="Create role"
      hide-footer
      title-tag="h4"
      size="lg"
      @close="closeModal"
    >
      <role-editor
        :role="entity"
        modal-mode
        @submit="handleCreate"
      >
        <b-alert
          v-if="entityPermissionsLength"
          show
          variant="primary"
        >
          Count of same permissions: {{ entityPermissionsLength }}
        </b-alert>
      </role-editor>
    </b-modal>
  </div>
</template>

<script>
import { VxTable, VxCellDate, VxCellButtons, VxCellTooltip } from '@/components/table'
import { roles, passDataToResource, useResource } from '@/services/resources'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import VxButton from '@/components/buttons/VxButton'
import RoleEditor from '@/views/admins/role/RoleEditor'
import { useModalEditor } from '@/services/modal'
import { computed, ref } from '@vue/composition-api'
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'Roles',
  components: {
    BAlert,
    VxButton,
    VxPageTitleActions,
    VxTable,
    VxCellTooltip,
    VxCellDate,
    RoleEditor
  },
  setup () {
    const tableRef = ref(null)

    const resource = passDataToResource(roles.getAll, {
      requestParams: { params: { include: 'permissions' } }
    })

    const { can: canCreate } = useResource(roles.create)

    const {
      can: canUpdatePermissions,
      callRequest: updatePermissionsRequest
    } = useResource(roles.updatePermissions)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()
    const entityPermissionsLength = computed(() => {
      const { permissions } = entity.value || {}
      return permissions && permissions.length.toString()
    })

    const columns = [
      { key: 'id' },
      { key: 'name' },
      { key: 'title' },
      {
        key: 'description',
        component: VxCellTooltip
      },
      {
        key: 'updated_at',
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const updatePermissions = async (id, permissions) => {
      if (!canUpdatePermissions) return

      const data = {
        permissions: permissions.map(({ id }) => id)
      }

      await updatePermissionsRequest({ urlParams: { id }, data })
    }

    const handleCreate = ({ data: { id } }) => {
      updatePermissions(id, entity.value.permissions)
      closeModal()
      tableRef.value.refresh()
    }

    const actionButtons = ({ id }) => {
      const createCopyButton = {
        ...buttons.add,
        label: 'Create copy',
        can: canCreate,
        onClick: (role) => {
          openModal(role)
        }
      }
      const editButton = {
        ...buttons.edit,
        to: buildRoute(path.updateRole, { params: { id } })
      }
      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(roles.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [createCopyButton, editButton, deleteButton]
    }

    return {
      tableRef,
      resource,
      columns,

      canCreate,
      entity,
      entityPermissionsLength,
      modalIsOpen,
      openModal,
      closeModal,
      handleCreate
    }
  }
}
</script>
